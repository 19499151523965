import React from 'react';
import { styled } from '../stitches.config';

const Wrapper = styled('div', {
  all: 'unset',
  border: '1px solid $default',
  borderRadius: '1.5rem',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: '1fr',
  padding: '$xSmall',
  textAlign: 'center',
  gap: '$medium',
});

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
  flexBasis: '0',
  flexGrow: '1',
  flexShrink: '1',
  padding: '$xSmall',

  variants: {
    selected: {
      true: {
        backgroundColor: '$default',
        border: '1px solid $default',
        borderRadius: '1.5rem',
        color: '$white',
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
        color: '$disabled',
      },
    },
    loading: {
      true: {
        cursor: 'wait',
        color: '$disabled',
      },
    },
  },
});

export type StatusControlChangeEvent = React.MouseEvent<HTMLButtonElement> & {
  target: HTMLButtonElement;
};

type StatusControlItemProps = {
  loading?: boolean;
  disabled?: boolean;
  value: string;
  selected?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const StatusControlItem = ({
  loading,
  disabled,
  children,
  selected,
  ...props
}: StatusControlItemProps) => (
  <Button
    type="button"
    disabled={disabled}
    loading={loading}
    selected={selected}
    {...props}
  >
    {children}
  </Button>
);

type StatusControlProps = {
  value?: string;
  onStatusControlChange?: (event: StatusControlChangeEvent) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const StatusControl = ({
  value,
  children,
  onStatusControlChange,
  ...props
}: StatusControlProps) => (
  <Wrapper {...props}>
    {React.Children.map(children, (child) => {
      const item = child as React.ReactElement<
        React.PropsWithChildren<StatusControlItemProps>
      >;
      return React.cloneElement(item, {
        onClick: item.props.disabled ? undefined : onStatusControlChange,
        selected: item.props.value === value,
        'aria-pressed': item.props.value === value,
        'aria-disabled': item.props.disabled ?? false,
      });
    })}
  </Wrapper>
);
