import ContentLoader from 'react-content-loader';
import { theme } from '../stitches.config';

export const TableCellLoader = ({
  width = 150,
  height = 8,
}: {
  width?: number;
  height?: number;
}) => (
  <ContentLoader
    backgroundColor={theme.colors.backgroundSurface.toString()}
    foregroundColor={theme.colors.contrastLow.toString()}
    height={height}
    width={width}
  >
    <rect x="0" y="0" width={width} height={height} />
  </ContentLoader>
);
